import { getReferrerAttribution } from "@outschool/attribution";
import { UserLocale } from "@outschool/gql-frontend-generated";
import { useLookupIP } from "@outschool/iplookup-client";
import { getLanguageFromLocale, useLocale } from "@outschool/localization";
import { captureError } from "@outschool/platform";
import {
  browseRootPath,
  pathnameWithoutLocale,
  searchPath,
  signUpPath,
} from "@outschool/routes";
import { guessBrowserTimeZone } from "@outschool/time";
import { useAnalytics, useTrackEvent } from "@outschool/ui-analytics";
import {
  ContinueWithGoogleOneTap,
  getPostLoginPath,
  useLoginWithGoogleOneTapMutationV2,
  useRefreshSessionToken,
  useSession,
  useTokenContext,
} from "@outschool/ui-auth";
import { useExperiment } from "@outschool/ui-experiments";
import { useNavigation } from "@outschool/ui-utils";
import { useRouter } from "next/router";
import React, { useEffect } from "react";

import { GOOGLE_CLIENT_ID } from "../Env";

function useLoginWithGoogleOneTap({
  onSuccess,
  onError,
}: {
  onSuccess: (isNewUser: boolean) => void;
  onError: (error: Error) => void;
}) {
  const loginWithGoogle = useLoginWithGoogleOneTapMutationV2();
  const analytics = useAnalytics();
  const { setTokens } = useTokenContext();
  const { ipInfoLoaded, isInGDPR } = useLookupIP();
  const locale = useLocale();
  const { userLocale } = getLanguageFromLocale(locale);
  const refreshSession = useRefreshSessionToken();

  return React.useCallback(
    async (token: string) => {
      const createOptions = {
        attribution: await analytics.attribution(),
        browserTimeZone: guessBrowserTimeZone(),
        isGiftCardSignup: false,
        isLeader: false,
        subscribe: ipInfoLoaded ? !isInGDPR : false,
        locale: userLocale === UserLocale.En ? undefined : userLocale,
      };

      const anonymousId = await analytics.anonymousId();
      const osRef = {
        ...getReferrerAttribution(),
        anonymousId,
      };

      try {
        const {
          data: { loginOrCreateAccountWithGoogleV2: response },
        } = await loginWithGoogle({
          variables: {
            createOptions,
            osRef,
            idToken: token,
          },
        });

        if (response === undefined) {
          onError(new Error("empty response"));
          return;
        }

        if (response.__typename === "LoginError") {
          onError(new Error("login error"));
        } else {
          const {
            isNewUser,
            authentication: { sessionToken, refreshToken },
          } = response;

          setTokens(sessionToken, refreshToken);
          await refreshSession();
          onSuccess(isNewUser);
        }
      } catch (e) {
        onError(e);
      }
    },
    [
      analytics,
      loginWithGoogle,
      onSuccess,
      onError,
      refreshSession,
      setTokens,
      isInGDPR,
      ipInfoLoaded,
      userLocale,
    ]
  );
}

const SignupWithGoogleOneTap = () => {
  const router = useRouter();
  const navigate = useNavigation();
  const trackEvent = useTrackEvent();

  const postLoginPath = getPostLoginPath() || "/";
  const signupToSearchOrPostLoginPath =
    postLoginPath === browseRootPath() ? searchPath() : postLoginPath;

  const loginWithGoogle = useLoginWithGoogleOneTap({
    onSuccess: isNewUser => {
      if (!isNewUser) {
        trackEvent("signin-by-google-onetap");
        router.reload();
      } else {
        navigate(signupToSearchOrPostLoginPath, { hardNav: true });
      }
    },
    onError: error => {
      console.log(error);
      captureError(error);
      navigate(signUpPath(), { hardNav: true });
    },
  });

  const { currentUserHasLoaded } = useSession();
  const { variant, trigger } = useExperiment({
    currentUserHasLoaded,
    experimentName: "HideOneTapHome2",
  });
  const isHome = pathnameWithoutLocale(window.location.pathname) === "/";
  useEffect(() => {
    if (isHome) {
      trigger();
    }
  }, [isHome, trigger]);
  const shouldHide = isHome && variant === "treatment";

  return (
    <ContinueWithGoogleOneTap
      clientId={GOOGLE_CLIENT_ID as string}
      loginWithGoogle={async credential => {
        loginWithGoogle(credential);
        trackEvent("one-tap-prompt-click");
      }}
      shouldPrompt={!shouldHide}
      onLoad={() => {}}
      onPrompt={notification => {
        if (notification.getMomentType() === "display") {
          trackEvent("one-tap-prompt-view");
        } else if (notification.getMomentType() === "skipped") {
          trackEvent("one-tap-prompt-cancel");
        }
      }}
    />
  );
};

export default SignupWithGoogleOneTap;
